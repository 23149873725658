import { Box, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AppBar from "./AppBar";

const useStyles = makeStyles(() => ({
  box: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: "50%",
    borderRadius: 8,
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Box>
      <AppBar hideSignIn />
      <Box className={classes.box}>
        <LinearProgress className={classes.line} />
      </Box>
    </Box>
  );
}
