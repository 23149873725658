import { useTheme } from "@emotion/react";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  card: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    cursor: (props) => (typeof props?.onClick === "function" ? "pointer" : undefined),
    "&:hover": {
      boxShadow: (props) => props?.theme?.shadows?.[typeof props?.onClick === "function" ? 6 : 1],
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
  },
  description: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: "12px",
  },
  image: {
    objectFit: "cover",
  },
}));


export default function SmallCard({ image_url: imageUrl, title, description, onClick }) {
  const theme = useTheme();
  const classes = useStyles({ onClick, theme });
  const [imageURL, setImageURL] = useState(imageUrl);

  useEffect(() => setImageURL(imageUrl), [imageUrl]);

  const imageMemo = useMemo(
    () =>
      imageURL ? (
        <img onError={() => setImageURL("")} height="60" width="60" src={imageURL} alt="Activity" className={classes.image} />
      ) : (
        <Skeleton animation={imageURL === "" ? false : undefined} height={60} width={60} variant="rectangular" />
      ),
    [imageURL, classes]
  );

  const titleMemo = useMemo(
    () => (
      <Typography
        sx={{ display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 1 }}
        variant="body2"
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {title || <Skeleton width="50%" />}
      </Typography>
    ),
    [title, classes]
  );

  const dateLineMemo = useMemo(
    () => (
      <Box
        sx={{ display: "-webkit-box", overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 3 }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography className={classes.description}>{description || <Skeleton width="40%" height={15} />}</Typography>
      </Box>
    ),
    [description, classes]
  );

  return (
    <Card onClick={onClick} className={classes.card}>
      {imageMemo}
      <Box px={2} display="flex" flexGrow={1} flexDirection="column" maxWidth="calc(100% - 100px)">
        {titleMemo}
        {dateLineMemo}
      </Box>
    </Card>
  );
}

SmallCard.defaultProps = {
  image_url: null,
  title: "",
  description: "",
  onClick: undefined,
};

SmallCard.propTypes = {
  image_url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};
