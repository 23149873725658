import { Tooltip } from "@mui/material";
import SDGInfoButton from "./SDGInfoButton";
import Autocomplete from "./Autocomplete";

export default function SDGAutocomplete({...props}) {
    const label = (
        <Tooltip title="United Nations Sustainable Development Goals">
            <span>UNSDG</span>
        </Tooltip>
    );

    return (
        <Autocomplete
            InfoButton={<SDGInfoButton />}
            label={label}
            {...props}
        />
    );
}
