import { useCallback, useState } from "react";
import { Box, Backdrop, Button, Card, CardContent, Dialog, Typography, Divider, Alert, IconButton } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Link, useHistory } from "react-router-dom";
import { Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import * as Yup from "yup";
import PropTypes from "prop-types";

import axios from "../utils/axios";
import { setProfile } from "../reducers/profile";
import TextField from "./TextField";
import { BACKDROP_COLOR } from "../styles/theme";

const cardPadding = { padding: [0, 2, 4] };

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: (props) => (props.mode === "light" ? "white" : undefined),
  },
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    width: "100%",
    maxWidth: 400,
  },
  link: {
    color: "black",
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "14px",
    letterSpacing: "0.6px",
    marginBottom: 10,
  },
}));
export default function SignIn({ open, onClose, activityId }) {
  const { mode } = useSelector((state) => state.style);
  const classes = useStyles({ mode });
  const history = useHistory();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);

  const handleSubmitFormik = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setErrors([]);
        await axios.post("auth/login", values);
        if (activityId) {
          await axios.get(`activities/join/${activityId}`);
        }
        const response = await axios.get("auth/profile");
        dispatch(setProfile(response.data.data));
        setSubmitting(false);
        onClose();
      } catch (error) {
        setErrors(
          Object.values(error.errors || { email: "These credentials do not match our records or email hasn't been verified yet." })
        );
        setSubmitting(false);
      }
    },
    [dispatch, onClose, activityId]
  );

  const handleNavigateToSignUp = useCallback(() => {
    onClose(true);
  }, [history, onClose]);

  const SignInSchema = useCallback(
    () =>
      Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().min(8, "Too Short!").required("Required"),
      }),
    []
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      BackdropComponent={styled(Backdrop, {
        name: "MuiModal",
        slot: "Backdrop",
        overridesResolver: (props, styles) => styles.backdrop,
      })({ zIndex: -1, backgroundColor: BACKDROP_COLOR })}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      transitionDuration={{ appear: 0 }}
    >
      <Card sx={cardPadding}>
        <CardContent className={classes.cardContent}>
          <Box display="flex" flexDirection="row" width="100%">
            <Box display="flex" flexGrow={1} />
            <Typography sx={{ fontSize: 28, fontWeight: 800 }}>Hello! 👋</Typography>
            <Box display="flex" flexGrow={1} justifyContent="flex-end" m="-30px -30px 30px 30px">
              <IconButton onClick={onClose} color="primary">
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Formik initialValues={{ email: "", password: "" }} validationSchema={SignInSchema} onSubmit={handleSubmitFormik}>
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <TextField required name="email" label="Email" autoComplete="username" />
                <TextField required name="password" label="Password" type="password" autoComplete="current-password" />
                <Link className={classes.link} to="/forgot_password">
                  Forgotten your password?
                </Link>
                <Box my={3}>
                  <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
                    Sign in
                  </Button>
                </Box>
                {errors.map((text) => (
                  <Alert sx={{ marginBottom: 2 }} key={text} severity="error">
                    {text}
                  </Alert>
                ))}
                <Divider />
                <Box my={2}>
                  <Typography sx={{ fontWeight: 600 }} align="center">
                    New to Student Volunteer Army?
                  </Typography>
                </Box>

                <Button fullWidth variant="outlined" onClick={handleNavigateToSignUp}>
                  Create an account
                </Button>
                <FormikErrorFocus offset={0} align="top" focusDelay={200} ease="linear" duration={1000} />
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Dialog>
  );
}

SignIn.defaultProps = {
  activityId: undefined,
};

SignIn.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activityId: PropTypes.number,
};
