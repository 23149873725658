import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import {
  AddCircleOutlined,
  AssignmentOutlined,
  Check,
  Description,
  Email,
  MenuBook,
  Notifications,
  RssFeed,
  Search,
  ThumbUpOutlined,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { Avatar, Badge, Button, ListItemAvatar, ListItemButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useCallback, useState } from "react";
import dayjs from "dayjs";
import { setStyleKey } from "../reducers/style";
import SmallCard from "./SmallCard";
import axios from "../utils/axios";

import calendarSvg from "../assets/calendar.png";

const useStyles = makeStyles(() => ({
  logButtonText: {
    marginRight: 18,
    marginLeft: 18,
    marginTop: 13,
  },
  buttonText: {
    marginTop: 13,
  },
  selected: {
    background: "transparent !important",
    fontWeight: 700,
    color: "#142667",
  },
  unselected: {
    fontWeight: 700,
    color: "#54585A",
  },
  listItem: {
    paddingRight: 0,
  },
  viewAll: {
    paddingRight: 0,
  },
  thumbUp: {
    marginRight: 12,
  },
}));

export default function DrawerList({ path, handleDrawerToggle }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    id,
    image_url: imageUrl,
    first_name: firstName,
    last_name: lastName,
    completed_activity_ids: completedActivityIds,
    unread_messages: unreadMessages,
    teams,
    upcoming_activity: upcomingActivity,
    bookmarked_activity: bookmarkedActivity,
    upcoming_activity_ids: upcomingActivityIds,
    bookmarked_activity_ids: bookmarkedActivityIds,
  } = useSelector((state) => state.profile);

  const [loading, setLoading] = useState(false);

  const handleSoSDownload = useCallback(async () => {
    setLoading(true);
    try {
      const link = await axios.get("summary_of_service");
      window.open(link.data);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={`${firstName || ""}${lastName || ""}`} src={imageUrl}>
              {imageUrl ? undefined : `${firstName ? firstName[0] : ""}${lastName ? lastName[0] : ""}`}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  lineHeight: "21px",
                }}
              >
                Hello{firstName ? ` ${firstName}` : ""}!
              </Typography>
            }
            secondary={
              <Button
                style={{ backgroundColor: "transparent" }}
                sx={{ marginLeft: -1 }}
                disableElevation
                onClick={() => history.push("/profile")}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: "14px",
                    textDecoration: "underline",
                  }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Edit Profile
                </Typography>
              </Button>
            }
          />
        </ListItem>
        {[
          { text: "Activity Feed", icon: <RssFeed />, path: "/" },
          { text: "Completed", icon: <Check />, path: "/completed" },
          { text: "Search", icon: <Search />, path: `/search?starts>=${dayjs().format("YYYY-MM-DD")}` },
          { text: "Service Record", icon: <AssignmentOutlined />, path: "/summary_of_service" },
          {
            text: "Notifications",
            icon: (
              <Badge badgeContent={unreadMessages} color="green">
                <Notifications />
              </Badge>
            ),
            path: "/notifications",
          },
          { text: "Contact SVA", icon: <Email />, path: "/contact_us" },
          { text: "Storybook", icon: <MenuBook />, path: "/storybook", devOnly: true },
        ].map((obj) => {
          const isSelected = obj.path === path;
          if (obj.devOnly && process.env.NODE_ENV === "production") {
            return null;
          }
          return (
            <ListItemButton
              onClick={() => {
                history.push(obj.path);
                handleDrawerToggle();
              }}
              key={obj.text}
              selected={isSelected}
              classes={{ selected: classes.selected }}
            >
              <ListItemIcon sx={{ fontWeight: 700, color: isSelected ? "primary.main" : "#54585A" }}>{obj.icon}</ListItemIcon>
              <ListItemText classes={{ primary: isSelected ? classes.selected : classes.unselected }} primary={obj.text} />
            </ListItemButton>
          );
        })}
        <ListItem>
          <ListItemText>
            <Typography style={{ fontWeight: 600 }}>Quick Actions</Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <Button
            onClick={() => dispatch(setStyleKey("displayLogAnActivityModal", true))}
            variant="contained"
            startIcon={<AddCircleOutlined />}
          >
            <Typography variant="button" style={{ fontWeight: 700 }} className={classes.logButtonText}>
              Log an activity
            </Typography>
          </Button>
        </ListItem>
        {!!completedActivityIds.length && (
          <ListItem>
            <Button
              color="green"
              onClick={handleSoSDownload}
              disabled={!id || loading}
              variant="contained"
              startIcon={<Description color="opposite" />}
            >
              <Typography variant="button" sx={{ color: "text.opposite", fontWeight: 700 }} className={classes.buttonText}>
                Summary of Service
              </Typography>
            </Button>
          </ListItem>
        )}
        <ListItem
          secondaryAction={
            upcomingActivityIds.length > 1 ? (
              <Button edge="end" aria-label="comments" onClick={() => history.push("/upcoming")} className={classes.viewAll}>
                View All
              </Button>
            ) : undefined
          }
          className={classes.listItem}
        >
          <ListItemText disableTypography>
            <Typography style={{ fontWeight: 600, alignItems: "center", display: "flex" }}>
              <img src={calendarSvg} alt="calendar" style={{ marginRight: 10, marginTop: 8 }} /> Next Activity
              {upcomingActivityIds.length ? ` (${upcomingActivityIds.length})` : ""}
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          {upcomingActivity && upcomingActivity.length === 0 && <ListItemText>You have no upcoming activity</ListItemText>}
        </ListItem>
        {(upcomingActivity || []).map((activity) => (
          <ListItem key={activity.id}>
            <SmallCard
              image_url={activity.image_url}
              title={activity.title}
              description={activity.description}
              onClick={() => history.push(`/activities/${activity.id}`)}
            />
          </ListItem>
        ))}
        {bookmarkedActivityIds.length > 0 && (
          <ListItem
            secondaryAction={
              bookmarkedActivityIds.length > 1 ? (
                <Button edge="end" aria-label="comments" onClick={() => history.push("/interested")} className={classes.viewAll}>
                  View All
                </Button>
              ) : undefined
            }
            className={classes.listItem}
          >
            <ListItemText disableTypography>
              <Typography style={{ fontWeight: 600, alignItems: "center", display: "flex" }}>
                <ThumbUpOutlined className={classes.thumbUp} color="green" />
                Interested ({bookmarkedActivityIds.length})
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        {bookmarkedActivity && bookmarkedActivity.length === 0 && (
          <ListItem>
            <ListItemText>You have no upcoming interested activities</ListItemText>
          </ListItem>
        )}
        {(bookmarkedActivity || []).map((activity) => (
          <ListItem key={activity.id}>
            <SmallCard
              image_url={activity.image_url}
              title={activity.title}
              description={activity.description}
              onClick={() => history.push(activity.programme_id ? `/programmes/${activity.programme_id}` : `/activities/${activity.id}`)}
            />
          </ListItem>
        ))}
        {teams.length > 0 && (
          <ListItem
            style={{ marginTop: 8 }}
            secondaryAction={
              <Button edge="end" aria-label="comments" onClick={() => history.push("/my_teams")} className={classes.viewAll}>
                View {teams.length > 3 ? "All" : ""}
              </Button>
            }
          >
            <ListItemText disableTypography>
              <Typography style={{ fontWeight: 600 }}>Your Teams{teams?.length ? ` (${teams.length})` : ""}</Typography>
            </ListItemText>
          </ListItem>
        )}
        {[...teams].splice(0, 3).map((team) => (
          <ListItem key={team.id}>
            <SmallCard image_url={team.image_url} title={team.name} description=" " onClick={() => history.push(`/teams/${team.id}`)} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

DrawerList.defaultProps = {
  handleDrawerToggle: () => {},
};

DrawerList.propTypes = {
  handleDrawerToggle: PropTypes.func,
  path: PropTypes.string.isRequired,
};
