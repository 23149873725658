import { Typography, Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import MUIDateTimePicker from "@mui/lab/DateTimePicker";
import { useCallback } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  optional: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray",
    marginLeft: 6,
  },
  textField: {
    "&> *": {
      borderRadius: 6,
    },
  },
  invisibleInput: {
    appearance: "none",
    border: "none",
    outline: "none",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 1,
    marginBottom: -1,
    opacity: 0.1,
    fontSize: 20,
  },
}));

export default function DateTimePicker({ name, label, required, onChange, disabled, ...rest }) {
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback(
    (newDate) => {
      helpers.setValue(newDate);
      if (typeof onChange === "function") {
        onChange(newDate);
      }
    },
    [helpers, onChange]
  );

  return (
    <Box>
      <Typography color={meta.touched && meta.error ? "error" : "text.primary"} gutterBottom className={classes.label}>
        {label}
        {required && "*"}
        {!required && !disabled && (
          <Typography display="inline" className={classes.optional}>
            (optional)
          </Typography>
        )}
      </Typography>
      <MUIDateTimePicker
        value={field.value}
        onChange={handleChange}
        inputFormat="DD/MM/YYYY hh:mm A"
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              fullWidth
              classes={{ root: classes.textField }}
              error={meta.touched && !!meta.error}
              helperText={(meta.touched && meta.error) || " "}
            />
            <input name={name} className={classes.invisibleInput} readOnly />
          </>
        )}
        disabled={disabled}
        {...rest}
      />
    </Box>
  );
}

DateTimePicker.defaultProps = {
  name: "",
  label: "",
  required: false,
  onChange: undefined,
  disabled: false,
};

DateTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
