import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, Alert, Grid, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import TextField from "./TextField";
import Autocomplete from "./Autocomplete";
import DateTimePicker from "./DateTimePicker";
import { lengthOfActivity, lengthOfActivityErrorMessage } from "../utils/helpers";
import SDGAutocomplete from "./SDGAutocomplete";

const useStyles = makeStyles(() => ({
  form: {
    width: "100%",
  },
}));

const countWords = (paragraph = " ") => paragraph.split(/[\s]+/).filter((text) => text?.length).length;

function Form({ valuesMemo, LogActivitySchema, onSubmit, goals, activityTypes, regions, submitButtonLabel, errors, skills }) {
  const classes = useStyles();
  return (
    <Formik initialValues={valuesMemo} validationSchema={LogActivitySchema} onSubmit={onSubmit}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField required name="title" label="Activity name" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SDGAutocomplete required name="goal" options={goals} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete name="activity_type" label="Activity type" options={activityTypes} />
            </Grid>
            <Grid item xs={12} sx={{ position: "relative" }}>
              <TextField
                multiline
                required
                name="description"
                label="Activity description"
                placeholder="Please use full sentences, the first 50 words will be in your Service Report"
              />
              <Box position="absolute" sx={{ right: 10, bottom: 25 }}>
                <Typography color="disabled">
                  {(formProps.values?.description || "").length ? countWords(formProps.values.description) : 0}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete required name="location" label="Region" options={regions} />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12}>
              <TextField name="address" label="Address" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateTimePicker
                required
                name="starts"
                label="Start date"
                onChange={(newDate) => {
                  if (
                    !formProps.values.ends &&
                    dayjs(newDate).format("HH:mm") !== dayjs().format("HH:mm") &&
                    dayjs(newDate).format("mm") !== dayjs().format("mm")
                  ) {
                    formProps.setFieldValue("ends", dayjs(newDate).add(1, "h"));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateTimePicker minDateTime={formProps.values.starts} required name="ends" label="End date" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                disabled
                name="length"
                label="Length of activity [hrs]"
                error={!!lengthOfActivityErrorMessage(formProps?.values?.starts, formProps?.values?.ends)}
                helperText={lengthOfActivityErrorMessage(formProps?.values?.starts, formProps?.values?.ends) || " "}
                value={lengthOfActivity(formProps?.values?.starts, formProps?.values?.ends)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete name="skills" multiple label="Skills" options={skills} />
            </Grid>
          </Grid>
          <Box my={3}>
            <Button type="submit" variant="contained" disabled={formProps.isSubmitting}>
              {submitButtonLabel}
            </Button>
          </Box>
          {errors.map((text) => (
            <Alert key={text} severity="error">
              {text}
            </Alert>
          ))}
          <FormikErrorFocus offset={0} align="top" focusDelay={200} ease="linear" duration={1000} />
        </form>
      )}
    </Formik>
  );
}

export default function IndividualActivityForm({
  initialValues = {},
  LogActivitySchema,
  handleSubmit,
  submitButtonLabel = "LOG ACTIVITY",
}) {
  const { locations: regions, skills, activity_types: activityTypes, goals } = useSelector(state => state.data);

  const [errors, setErrors] = useState([]);

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setErrors([]);
        await handleSubmit(values);
        setSubmitting(false);
      } catch (error) {
        setErrors(Object.values(error.errors || { email: "Unknown error, please contact SVA for help." }));
        setSubmitting(false);
      }
    },
    [handleSubmit]
  );

  const valuesMemo = useMemo(() => {
    const values = { ...initialValues };
    if (values.goal_id) {
      values.goal = goals.find((goal) => goal.id === values.goal_id);
    }
    if (values.location_id) {
      values.location = regions.find((location) => location.id === values.location_id);
    }
    if (values.activity_type_id) {
      values.activity_type = activityTypes.find((activityType) => activityType.id === values.activity_type_id);
    }
    return values;
  }, [regions, goals, activityTypes, initialValues]);

  if (!regions.length || !goals.length || !activityTypes.length || !skills.length) {
    return (
      <Box my={20}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Form
      valuesMemo={valuesMemo}
      LogActivitySchema={LogActivitySchema}
      onSubmit={onSubmit}
      goals={goals}
      skills={skills}
      activityTypes={activityTypes}
      regions={regions}
      submitButtonLabel={submitButtonLabel}
      errors={errors}
    />
  );
}

IndividualActivityForm.defaultProps = {
  initialValues: {},
  handleSubmit: () => {},
  submitButtonLabel: "LOG ACTIVITY",
  values: {},
};

IndividualActivityForm.propTypes = {
  initialValues: PropTypes.shape({}),
  LogActivitySchema: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  values: PropTypes.shape({
    description: PropTypes.string,
    starts: PropTypes.string,
    ends: PropTypes.string,
  }),
};

Form.propTypes = {
  valuesMemo: PropTypes.shape({
    description: PropTypes.string,
    starts: PropTypes.string,
    ends: PropTypes.string,
  }).isRequired,
  LogActivitySchema: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  activityTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
