export const BACKDROP_COLOR = "rgba(20, 38, 103, 0.7)";

const THEME = {
  shape: {
    borderRadius: 20,
  },
  typography: {
    fontFamily: "Raleway",
    button: {
      textTransform: "none",
      height: "40px",
    },
  },
};

export const LIGHT_THEME = {
  ...THEME,
  palette: {
    mode: "light",
    primary: {
      main: "#142667",
      light: "#D8DEF1",
    },
    green: {
      main: "#00A59B",
    },
    background: {
      paper: "#F3F4F7",
      default: "#F3F4F7",
    },
    opposite: {
      main: "#F3F4F7",
    },
    text: {
      primary: "#241C15",
      secondary: "#54585A",
      opposite: "#F3F4F7",
      strong: "#54585A",
    },
  },
};

export const DARK_THEME = {
  ...THEME,
  palette: {
    mode: "dark",
    primary: {
      main: "#F3F4F7",
    },
    background: {
      paper: "#1d1d1d",
      default: "#1d1d1d",
    },
    green: {
      main: "#FDDA38",
    },
    opposite: {
      main: "#142667",
    },
    text: {
      opposite: "#142667",
      strong: "#ffffff",
    },
  },
};
