import { Button, IconButton, AppBar, Toolbar, Box, Typography, Divider } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import { closeSignInModal, openSignInModal, setStyleKey } from "../reducers/style";
import logo from "../assets/logo.svg";
import whiteLogo from "../assets/whiteLogo.svg";
import SignIn from "./SignIn";
import axios from "../utils/axios";
import { emptyProfile } from "../reducers/profile";
import LogAnActivity from "./LogAnActivity";

import doTheMahi from "../assets/do_the_mahi.png";
import SignUp from "../views/SignUp";

const useStyles = makeStyles(() => ({
  homeButton: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  imageStyle: {
    maxWidth: "25vw",
  },
}));

export default function CustomAppBar({ hideSignIn, handleDrawerToggle, handleCloseDrawer }) {
  const [loading, setLoading] = useState(false);
  const [displaySignUpModal, setDisplaySignUpModal] = useState(false);

  const { mode, displaySignIn, displaySignInModal, displayLogAnActivityModal } = useSelector((state) => state.style);
  const { id, institution } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleSignUp = useCallback(() => setDisplaySignUpModal(true), []);

  const handleCloseSignUp = useCallback(() => setDisplaySignUpModal(false), []);

  const handleSignIn = useCallback(() => dispatch(openSignInModal()), [dispatch]);
  const handleCloseSignIn = useCallback(
    (isSignUp) => {
      dispatch(closeSignInModal());
      if (isSignUp === true) {
        setDisplaySignUpModal(true);
      }
    },
    [dispatch]
  );

  const handleCloseLogAnActivity = useCallback(() => dispatch(setStyleKey("displayLogAnActivityModal", false)), [dispatch]);

  const signInButton = useMemo(() => {
    if (id || hideSignIn || !displaySignIn) {
      return null;
    }
    return (
      <Button onClick={handleSignIn} sx={{ paddingLeft: 4, paddingRight: 4, fontWeight: 700 }} variant="contained">
        Sign In
      </Button>
    );
  }, [id, hideSignIn, handleSignIn, displaySignIn]);

  const logOutButton = useMemo(() => {
    if (!id || !displaySignIn) {
      return null;
    }
    const logOut = async () => {
      setLoading(true);
      try {
        await axios.get("auth/logout");
        handleCloseDrawer();
        dispatch(emptyProfile());
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    };
    return (
      <LoadingButton loading={loading} onClick={logOut} sx={{ paddingLeft: 4, paddingRight: 4, fontWeight: 700 }} variant="outlined">
        Sign out
      </LoadingButton>
    );
  }, [id, loading, dispatch, displaySignIn, handleCloseDrawer]);

  const signInBanner = useMemo(() => {
    if (id || hideSignIn || !displaySignIn) {
      return null;
    }
    return (
      <Box position="absolute" sx={{ top: 0, left: 0, right: 0 }}>
        <Box sx={{ zIndex: (theme) => [1, 1, theme.zIndex.drawer + 1], marginRight: "auto", marginLeft: "auto" }} position="relative">
          <Box display="flex" justifyContent="center" style={{ height: 400 }}>
            {/* <img src={doTheMahi} style={{ objectFit: "cover", height: "400px", width: "100%" }} alt="Do the Mahi" /> */}
            <Box
              sx={{
                width: "100%",
                zIndex: (theme) => [1, 1, theme.zIndex.drawer + 1],
              }}
              style={{
                backgroundImage: `url(${doTheMahi})`,
                height: 400,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center top",
              }}
              alignItems="flex-end"
              justifyContent="center"
              display="flex"
            >
              <Box
                sx={{ backgroundColor: "rgba(230, 241, 255, 0.9)", width: "calc(100% - 16px)", padding: "8px", maxWidth: 1407 }}
                alignItems="center"
                display="flex"
                justifyContent="center"
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Typography
                  sx={{
                    fontSize: 26,
                    fontWeight: 800,
                    lineHeight: ["36px", "48px", "64px"],
                    letterSpacing: "0.6px",
                    textAlign: "center",
                  }}
                  pr={3}
                  color="primary.main"
                >
                  Become a volunteer today!
                </Typography>

                <Button onClick={handleSignUp} size="large" color="green" variant="contained">
                  <Typography sx={{ fontWeight: 700 }} color="text.opposite">
                    SIGN UP FOR AN ACCOUNT
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }, [id, hideSignIn, displaySignIn, handleSignUp]);

  const handleGoToHomePage = useCallback(() => {
    history.push("/");
  }, [history]);

  // const handleSwitchMode = useCallback(() => dispatch(setMode(mode === "light" ? "dark" : "light")), [dispatch, mode]);

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2, background: mode === "light" ? "#fff" : undefined }}>
        <Toolbar>
          {id && (
            <Box display={{ md: "none" }} mt={0.5} mr={1}>
              <IconButton onClick={handleDrawerToggle}>
                <Menu color="primary" />
              </IconButton>
            </Box>
          )}
          <button onClick={handleGoToHomePage} type="button" className={classes.homeButton}>
            <img src={mode === "light" ? logo : whiteLogo} height="35" alt="SVA" data-testid="Logo" className={classes.imageStyle} />
          </button>
          {institution.image_url && (
            <Box display={{ xs: "none", sm: "flex" }} flexDirection="row" mx={1}>
              <Divider orientation="vertical" sx={{ height: "35px", marginRight: 1 }} />
              <img src={institution.image_url} height="35" className={classes.imageStyle} alt={institution.name} />
            </Box>
          )}
          <Box flexGrow={1} />
          {signInButton}
          {logOutButton}
          {/* <IconButton onClick={handleSwitchMode}>{mode === "light" ? <WbSunny /> : <Brightness2 />}</IconButton> */}
        </Toolbar>
      </AppBar>
      {signInBanner}
      {displaySignInModal && <SignIn open={displaySignInModal} onClose={handleCloseSignIn} />}
      {!displaySignInModal && displaySignUpModal && <SignUp open={!displaySignInModal && displaySignUpModal} onClose={handleCloseSignUp} />}
      {displayLogAnActivityModal && <LogAnActivity open={displayLogAnActivityModal} onClose={handleCloseLogAnActivity} />}
    </>
  );
}

CustomAppBar.defaultProps = {
  hideSignIn: false,
  handleDrawerToggle: () => {},
  handleCloseDrawer: () => {},
};

CustomAppBar.propTypes = {
  hideSignIn: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  handleCloseDrawer: PropTypes.func,
};
