import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: undefined,
  available: {},
  bookmarked_activity_ids: [],
  bookmarked_activity: undefined,
  completed_activity_ids: [],
  devices: [],
  email: "",
  ethnicities: [],
  first_name: "",
  full_name: "",
  institution: {},
  issued_awards: [],
  last_name: "",
  phone: "",
  roles: [],
  skills: [],
  suggested_activity_ids: [],
  suggested_program_ids: [],
  teams: [],
  upcoming_activity_ids: [],
  upcoming_activity: undefined,
  unread_messages: 0,
  volunteer_details: {
    dob: undefined,
    student_id: undefined,
    emergency_contact_name: "",
    emergency_contact_email: "",
    emergency_contact_phone: "",
    secondary_year: null,
    has_graduated: 0,
  },
  volunteered_minutes: 0,
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, action) {
      const keys = Object.keys(action.payload);
      keys.forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    logout(state) {
      const keys = Object.keys(state);
      keys.forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setProfileKey(state, action) {
      state[action.payload.id] = action.payload.value;
    },
    increaseUnread(state) {
      state.unread_messages += 1;
    },
    decreaseUnread(state) {
      state.unread_messages -= 1;
    },
  },
});

export const { reducer } = slice;

export const setProfile = (values) => (dispatch) => {
  dispatch(slice.actions.setProfile(values));
};

export const logOut = () => (dispatch) => {
  dispatch(slice.actions.logout());
};

export const emptyProfile = () => (dispatch) => {
  dispatch(slice.actions.logout());
};

export const setProfileKey = (id, value) => (dispatch) => {
  dispatch(slice.actions.setProfileKey({ id, value }));
};

export const increaseUnread = () => (dispatch) => {
  dispatch(slice.actions.increaseUnread());
};

export const decreaseUnread = () => (dispatch) => {
  dispatch(slice.actions.decreaseUnread());
};

// export const selectEvent = eventId => async dispatch => {
//   dispatch(slice.actions.selectEvent({ eventId }));
// };
