import * as Yup from "yup";
import dayjs from "dayjs";

export const openNewTab = (url) => {
  window.open(url);
};

// eslint-disable-next-line no-promise-executor-return
export const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default openNewTab;

export const validateEndDate = () =>
  Yup.date()
    .required("End date is required")
    .nullable()
    .default(undefined)
    .min(Yup.ref("starts"), "End date can't be before start date")
    .test({
      name: "10minutes",
      message: "Activity must be at least 10 minutes long",
      test: (value, { parent }) => {
        if (!value || !parent.starts) {
          return true;
        }
        return dayjs(parent.starts).add(10, "minutes").isBefore(value);
      },
    })
    .test({
      name: "InRange",
      message: "Activity must be less than 2 days",
      test: (value, { parent }) => {
        if (!value || !parent.starts) {
          return true;
        }
        return dayjs(parent.starts).add(2, "day").isAfter(value);
      },
    })
    .test({
      name: "Before",
      message: "End date must be after the start date",
      test: (value, { parent }) => {
        if (!value || !parent.starts) {
          return true;
        }
        return dayjs(parent.starts).isBefore(value);
      },
    });


export const lengthOfActivity = (start, end) =>
  !start || !end || !dayjs(start).isValid() || !dayjs(end).isValid() || dayjs(start).isAfter(dayjs(end))
    ? "N/A"
    : `${dayjs(end).diff(dayjs(start), "hours", false)}:${dayjs(end).diff(dayjs(start), "minutes", false) % 60}`;

export const lengthOfActivityErrorMessage = (start, end) => {
  if (!start || !end || !dayjs(start).isValid() || !dayjs(end).isValid()) {
    return "";
  }
  if (dayjs(start).isAfter(dayjs(end))) {
    return "End date must be after start date";
  }
  return "";
};
