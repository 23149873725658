import { Checkbox, FormControlLabel, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import { useCallback } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  label: {
    color: "red",
  },
  helperText: {
    color: "red",
    fontSize: "0.75rem",
    marginLeft: 14,
  },
  formControl: {
    alignItems: "flex-start",
  },
  checkbox: {
    paddingTop: 0,
  },
}));

export default function CustomCheckbox({ name, label, required }) {
  const [field, meta, helpers] = useField({ name, type: "checkbox" });
  const classes = useStyles();

  const handlePress = useCallback(
    (e) => {
      helpers.setValue(e.target.checked);
    },
    [helpers]
  );

  return (
    <Box flexDirection="column" display="flex">
      <FormControlLabel
        className={classes.formControl}
        control={<Checkbox onChange={handlePress} checked={!!field.value} classes={{ root: classes.checkbox }} />}
        label={
          <Box display="flex" flexDirection="row" className={meta.touched && !!meta.error ? classes.label : undefined}>
            {label}
            {required && "*"}
          </Box>
        }
        name={name}
      />
      <Typography color="red" className={classes.helperText}>
        {(meta.touched && meta.error) || " "}
      </Typography>
    </Box>
  );
}

CustomCheckbox.defaultProps = {
  name: "",
  label: "",
  required: false,
};

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  required: PropTypes.bool,
};
