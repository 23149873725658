import { Box, Container, Fade, Toolbar } from "@mui/material";
import React, { Suspense, lazy, useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import CustomAppBar from "../components/AppBar";
import Drawer from "../components/Drawer";
import Loading from "../components/Loading";
import UpdateDetailsDialog from "../components/UpdateDetailsDialog";

const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("../views/Feed")),
  },
  {
    exact: true,
    path: "/forgot_password",
    component: lazy(() => import("../views/ForgotPassword")),
  },
  {
    exact: true,
    path: "/activities/:id",
    component: lazy(() => import("../views/Activity")),
  },
  {
    exact: true,
    path: "/programmes/:id",
    component: lazy(() => import("../views/Programme")),
  },
  {
    exact: true,
    path: "/summary_of_service",
    component: lazy(() => import("../views/SummaryOfService")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/search",
    component: lazy(() => import("../views/Search")),
  },
  {
    exact: true,
    path: "/completed",
    component: lazy(() => import("../views/Completed")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/interested",
    component: lazy(() => import("../views/Interested")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/Sustainable-Development-Goals",
    component: lazy(() => import("../views/SustainableDevelopmentGoals")),
  },
  {
    exact: true,
    path: "/contact_us",
    component: lazy(() => import("../views/ContactSVA")),
    authGuarded: true,
  },

  {
    exact: true,
    path: "/my_teams",
    component: lazy(() => import("../views/MyTeams")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/teams/:id",
    component: lazy(() => import("../views/Team")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/teams/:id/members",
    component: lazy(() => import("../views/TeamMembers")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/upcoming",
    component: lazy(() => import("../views/Upcoming")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/notifications",
    component: lazy(() => import("../views/Notifications")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "/profile",
    component: lazy(() => import("../views/Profile")),
    authGuarded: true,
  },
  {
    exact: true,
    path: "*",
    component: lazy(() => import("../views/404")),
  },
];

const devRoutes = [
  {
    exact: true,
    path: "/storybook",
    component: lazy(() => import("../views/Storybook")),
  },
  ...routes,
];

function AuthGuard({ isAuthed, children }) {
  const history = useHistory();
  const { id } = useSelector((state) => state.profile);
  const { displaySignIn } = useSelector((state) => state.style);
  useEffect(() => {
    if (isAuthed && !id && displaySignIn) {
      history.push("/");
    }
  }, [id, isAuthed, history, displaySignIn]);

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isAuthed: PropTypes.bool,
};

AuthGuard.defaultProps = {
  isAuthed: false,
};

export default function Routes() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const { id } = useSelector((state) => state.profile);
  const { displaySignIn } = useSelector((state) => state.style);

  const drawerWidth = useMemo(() => (id ? 325 : 0), [id]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);

  const handleCloseDrawer = useCallback(() => setMobileOpen(false), []);

  const boxStyle = useMemo(
    () => ({
      flexGrow: 1,
      pt: 3,
      pl: { md: 3 },
      marginLeft: { md: `${drawerWidth}px` },
      marginTop: id || !displaySignIn ? 0 : "350px",
      minHeight: "100vh",
      overflow: "hidden",
    }),
    [id, displaySignIn, drawerWidth]
  );

  const containerStyle = useMemo(() => ({ bgcolor: "background.paper" }), []);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {(process.env.NODE_ENV === "production" ? routes : devRoutes).map((route) => {
          const Component = route.component;
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <AuthGuard isAuthed={route.authGuarded}>
                  <CustomAppBar {...props} handleCloseDrawer={handleCloseDrawer} handleDrawerToggle={handleDrawerToggle} />
                  <Fade in exit>
                    <Container maxWidth={false} sx={containerStyle}>
                      <Container maxWidth="lg" sx={{ position: "relative" }}>
                        <Drawer
                          {...props}
                          path={route.path}
                          drawerWidth={drawerWidth}
                          mobileOpen={mobileOpen}
                          handleDrawerToggle={handleDrawerToggle}
                          handleCloseDrawer={handleCloseDrawer}
                        />
                        <Box component="main" sx={boxStyle}>
                          <Toolbar />
                          <Component {...props} />
                        </Box>
                      </Container>
                    </Container>
                  </Fade>
                  <UpdateDetailsDialog />
                </AuthGuard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
