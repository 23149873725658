import axiosPackage from "axios";

const getBaseUrl = () => {

  const proto = process.env.REACT_APP_API_PROTOCOL ?? "https://";
  const subdomain = process.env.REACT_APP_API_SUBDOMAIN ?? false;
  const domain = process.env.REACT_APP_API_DOMAIN ?? window.location.hostname.replace(/^(staging-|preview-|testing-)?volunteer\./, "");
  const path = process.env.REACT_APP_API_PATH ?? "/api/";

  const baseUrl = [
    proto,
    subdomain ? `${subdomain}.` : "",
    domain,
    path,
  ];

  return baseUrl.join("");
}

const axios = axiosPackage.create({
  withCredentials: true,
});

axios.interceptors.request.use(
  async (config) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    config.headers = headers;
    config.baseURL = getBaseUrl();
    return config;
  },
  (error) => 
    // console.log("AXIOS Request Error");
     Promise.reject(error)
  
);

axios.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      axiosPackage.isCancel(error) ? "Response was cancelled" : (error.response && error.response.data) || "Something went wrong"
    )
);

export default axios;
