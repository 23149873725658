import { useEffect, useMemo, useState } from "react";
import { Box, createTheme, ThemeProvider, Alert, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
// import useMediaQuery from "@mui/material/useMediaQuery";
import DayJsUtils from "@date-io/dayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Helmet } from "react-helmet";

import { LIGHT_THEME, DARK_THEME } from "./styles/theme";
import renderRoutes from "./utils/routes";
import {
  // setMode,
  setStyleKey,
} from "./reducers/style";
import axios from "./utils/axios";
import { setProfile, setProfileKey } from "./reducers/profile";
import { setDataKey } from "./reducers/data";

function App() {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const { mode } = useSelector((s) => s.style);
  const { upcoming_activity_ids: uAI, bookmarked_activity_ids: bAI } = useSelector((s) => s.profile);
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const args = useMemo(
    () => ({
      components: {
        MuiCardContent: {
          styleOverrides: {
            root: {
              backgroundColor: mode === "light" ? "#fff" : undefined,
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: mode === "light" ? "#fff" : undefined,
            },
          },
        },
      },
    }),
    [mode]
  );
  const theme = createTheme(mode === "light" ? LIGHT_THEME : DARK_THEME, args);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await axios.get("auth/profile");
        if (!response.data.data?.upcoming_activity_ids?.length) {
          dispatch(setProfileKey("upcoming_activity", []));
        }
        dispatch(setProfile(response.data.data));
        const numberOfUnread = await axios.get("notifications?filter[read]=0");
        dispatch(setProfileKey("unread_messages", numberOfUnread?.data?.meta?.total || 0));
      } catch (error) {
        //
      } finally {
        dispatch(setStyleKey("displaySignIn", true));
      }
    };
    load();
  }, [dispatch]);

  useEffect(() => {
    const getActivities = async () => {
      try {
        const response = await axios.get(`activities?limit=1&sort=starts asc&filter[id]=${uAI.join("||")}`);
        dispatch(setProfileKey("upcoming_activity", response?.data?.data || []));
      } catch (error) {
        dispatch(setProfileKey("upcoming_activity", []));
      }
    };
    if (uAI.length) {
      getActivities();
    }
  }, [dispatch, uAI]);

  useEffect(() => {
    const getActivities = async () => {
      try {
        const response = await axios.get(`activities?limit=1&sort=starts asc&filter[id]=${bAI.join("||")}`);
        dispatch(setProfileKey("bookmarked_activity", response?.data?.data || []));
      } catch (error) {
        dispatch(setProfileKey("bookmarked_activity", []));
      }
    };
    if (bAI.length) {
      getActivities();
    }
  }, [dispatch, bAI]);

  useEffect(() => {
    const getConfigData = async () => {
      try {
        const [locations, skills, activityTypes, goals, beneficiaries] = await Promise.all([
          axios.get("locations"),
          axios.get("skills"),
          axios.get("activity_types"),
          axios.get("goals"),
          axios.get("beneficiaries"),
        ]);
        dispatch(setDataKey("locations", locations.data.data));
        dispatch(setDataKey("skills", skills.data.data));
        dispatch(setDataKey("activity_types", activityTypes.data.data));
        dispatch(setDataKey("goals", goals.data.data));
        dispatch(setDataKey("beneficiaries", beneficiaries.data.data));

      } catch {
        setErrors(["An error initializing has occured, please try again later or contact SVA for help"]);
      }
    };
    getConfigData();
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Student Volunteer Army</title>
        <meta
          name="description"
          content="The Student Volunteer Army enables students & members of the community with opportunities to volunteer. Learn new skills, meet new people, community impact."
        />
        <meta name="facebook-domain-verification" content="gbo8o3nbbmuudg1y5nmx4063ry8mhk" />
        <link rel="canonical" href={window.location.origin} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DayJsUtils}>
          {errors.map((text) => (
            <Modal open onClick={() => setErrors([])}>
              <Alert sx={{
                display: "inline-block",
                position: "fixed",
                left: "50%",
                top: "30%",
                transform: "translate(-50%, -70%)"
              }} severity="error">{text}</Alert>
            </Modal>
          ))}
          <Box sx={{ display: "flex" }}>
            <Router>{renderRoutes()}</Router>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
