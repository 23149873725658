import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
};

const slice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setDataKey(state, action) {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const {reducer} = slice;

export const setDataKey = (id, value) => (dispatch) => {
  dispatch(slice.actions.setDataKey({ id, value }));
};
