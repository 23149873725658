import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function useGetYearlyPopup() {
  const { push } = useHistory();
  const { id } = useSelector((state) => state.profile);

  const [displayPopUp, setDisplayPopUp] = useState(false);

  useEffect(() => {
    if (id) {
      // Check if the user has dismissed the dialog
      let userHasDismissed = localStorage.getItem("user_dismissed_new_year_popup");
      let nextDisplayDate = localStorage.getItem("next_display_new_year_popup");
      // Clear if the popup was dismissed last year, as the pop up should be displayed every year from 1/1 - 31/3
      if (userHasDismissed && dayjs(userHasDismissed).year() !== dayjs().year()) {
        localStorage.removeItem("user_dismissed_new_year_popup");
        userHasDismissed = null;
      }
      // Clear if the popup was dismissed last year, as the pop up should be displayed every year from 1/1 - 31/3
      if (nextDisplayDate && dayjs(nextDisplayDate).year() !== dayjs().year()) {
        localStorage.removeItem("next_display_new_year_popup");
        nextDisplayDate = null;
      }
      // Display if:
      // 1. Jan - Mar and the user didn't dismiss the popup
      // 2. The user has postponed the popup and that postponed time has passed
      if (!userHasDismissed) {
        if ((!nextDisplayDate && [0, 1, 2].includes(dayjs().month())) || dayjs().isAfter(dayjs(nextDisplayDate))) {
          setDisplayPopUp(true);
        }
      }
    }
  }, [id]);

  const handleDismiss = useCallback(() => {
    setDisplayPopUp(false);
    localStorage.setItem("next_display_new_year_popup", dayjs().add(1, "day").toISOString());
  }, []);

  const handleDismissUntilNextYear = useCallback(() => {
    setDisplayPopUp(false);
    localStorage.setItem("user_dismissed_new_year_popup", dayjs().toISOString());
  }, []);

  const handleDismissForAWeek = useCallback(() => {
    setDisplayPopUp(false);
    localStorage.setItem("next_display_new_year_popup", dayjs().add(7, "day").toISOString());
  }, []);

  const handleViewProfile = useCallback(() => {
    setDisplayPopUp(false);
    localStorage.setItem("user_dismissed_new_year_popup", dayjs().toISOString());
    push("/profile");
  }, []);

  return {
    displayPopUp,
    handleDismiss,
    handleDismissUntilNextYear,
    handleDismissForAWeek,
    handleViewProfile,
  };
}

export default useGetYearlyPopup;
