import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  displaySignIn: false,
  displaySignInModal: false,
  displayLogAnActivityModal: false,
};

const slice = createSlice({
  name: "style",
  initialState,
  reducers: {
    setMode(state, action) {
      state.mode = action.payload;
    },
    setStyleKey(state, action) {
      state[action.payload.id] = action.payload.value;
    },
  },
});

export const {reducer} = slice;

export const setMode = (mode) => (dispatch) => {
  dispatch(slice.actions.setMode(mode));
};

export const setStyleKey = (id, value) => (dispatch) => {
  dispatch(slice.actions.setStyleKey({ id, value }));
};

export const openSignInModal = () => (dispatch) => {
  dispatch(slice.actions.setStyleKey({ id: "displaySignInModal", value: true }));
};

export const closeSignInModal = () => (dispatch) => {
  dispatch(slice.actions.setStyleKey({ id: "displaySignInModal", value: false }));
};
