import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Info } from "@mui/icons-material";
import { useCallback } from "react";

export default function SDGInfoButton({ ...props }) {
  const { push } = useHistory();
  const navToSDGGoals = useCallback(() => push("/Sustainable-Development-Goals"), [push]);
  return (
    <IconButton sx={{ padding: 0, marginTop: -0.5, marginLeft: 1 }} onClick={navToSDGGoals} {...props}>
      <Info sx={{ fontSize: 16, color: "primary.main" }} />
    </IconButton>
  );
}
