import {
  Typography,
  Box,
  TextField as MUITextField,
  Skeleton,
  Select,
  MenuItem,
} from "@mui/material";
import { useField } from "formik";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const useStyles = makeStyles(() => ({
  label: { fontSize: 14, fontWeight: 600 },
  optional: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray",
    marginLeft: 6,
  },
  textField: {
    "&> *": {
      borderRadius: 6,
    },
  },
  skeleton: {
    height: 93,
    borderRadius: 4,
    marginTop: -10,
  },
  input: {
    "& input": {
      paddingLeft: "65px",
    },
  },
  countrySelect: {
    position: "absolute",
    bottom: 0,
    left: 0,
    marginBottom: "23px",
    marginLeft: "16px",
    zIndex: 1,
    "& fieldset": {
      border: "none",
    },
  },
  countryDropdown: {
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

export default function PhoneField({
  loading,
  name,
  label,
  countryName,
  countryLabel,
  countryOptions,
  required,
  onChange,
  setFieldValue,
  ...rest
}) {
  const classes = useStyles();
  const [field, meta] = useField(name);

  const initialCountry = countryOptions.length ? countryOptions[0].name : "";

  const [chosenCountry, setChosenCountry] = useState(initialCountry);
  const [phoneNumber, setPhoneNumber] = useState("");

  const findCountryCode = (chosenCountryName) =>
    countryOptions.find((country) => country.name === chosenCountryName).code;

  const handleCountryChange = (e) => {
    setChosenCountry(e.target.value);
    const countryCode = findCountryCode(e.target.value);
    const fullNumber = countryCode + phoneNumber;
    setFieldValue(field.name, fullNumber);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
    const countryCode = findCountryCode(chosenCountry);
    const fullNumber = countryCode + e.target.value;
    setFieldValue(field.name, fullNumber);
  };

  useEffect(() => {
    // set initial value on load
    if (field.value) {
      const existingNumberHasCountryCode = field.value.startsWith("+");

      if (!existingNumberHasCountryCode) {
        const existingNumberBeginsWithZero = field.value.startsWith("0");

        if (existingNumberBeginsWithZero) {
          setPhoneNumber(field.value.substring(1));
          return;
        }

        setPhoneNumber(field.value);
        return;
      }

      const code = field.value.substring(0, 3);
      const number = field.value.substring(3);

      setChosenCountry(
        countryOptions.find((country) => country.code === code).name
      );
      setPhoneNumber(number);
    }
  }, [field.value]);

  return (
    <>
      <Select
        v-if="countryOptions.length"
        labelId="country-select-label"
        id="country-select"
        name={countryName}
        defaultValue={initialCountry}
        value={chosenCountry}
        label={countryLabel}
        className={classes.countrySelect}
        MenuProps={{ classes: { paper: classes.countryDropdown } }}
        onChange={handleCountryChange}
      >
        {countryOptions.map((country) => (
          <MenuItem key={country.name} value={country.name}>
            {country.code}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.input}>
        <Typography
          color={
            (meta.touched && meta.error) || rest.error
              ? "error"
              : "text.primary"
          }
          gutterBottom
          className={classes.label}
        >
          {label}
          {required && "*"}
          {!required && !rest.disabled && (
            <Typography display="inline" className={classes.optional}>
              (optional)
            </Typography>
          )}
        </Typography>
        {loading ? (
          <Skeleton animation={false} className={classes.skeleton} />
        ) : (
          <MUITextField
            fullWidth
            rows={1}
            classes={{ root: classes.textField }}
            value={phoneNumber}
            name="phone-number"
            onChange={handlePhoneChange}
            error={meta.touched && !!meta.error}
            helperText={(meta.touched && meta.error) || " "}
            {...rest}
          />
        )}
      </Box>
    </>
  );
}

PhoneField.defaultProps = {
  loading: false,
  name: "phone",
  label: "Phone Number",
  countryName: "country",
  countryLabel: "Country",
  countryOptions: [],
  required: false,
  onChange: undefined,
  setFieldValue: () => {},
};

PhoneField.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  countryName: PropTypes.string,
  countryLabel: PropTypes.string,
  countryOptions: PropTypes.arrayOf(PropTypes.object),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
};
