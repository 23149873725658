import { combineReducers } from "@reduxjs/toolkit";
import { reducer as profileReducer } from "./profile";
import { reducer as styleReducer } from "./style";
import { reducer as dataReducer } from "./data";

const rootReducer = combineReducers({
  profile: profileReducer,
  style: styleReducer,
  data: dataReducer,
});

export default rootReducer;
