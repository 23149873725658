import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import DrawerList from "./DrawerList";

export default function CustomDrawer({ mobileOpen, window, drawerWidth, handleDrawerToggle, path, handleCloseDrawer }) {
  const container = window !== undefined ? () => window().document.body : undefined;

  const isDesktop = useMediaQuery("(min-width:900px)");

  const { id } = useSelector((state) => state.profile);

  useEffect(() => handleCloseDrawer(), [handleCloseDrawer, path]);

  const desktopStyle = useMemo(
    () => ({
      display: { xs: "none", md: "block" },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: drawerWidth - 20,
        border: "none",
        position: "fixed",
        left: `max(0px, calc(50% - ${1.66 * drawerWidth}px))`,
        top: "0%",
      },
    }),
    [drawerWidth]
  );

  const navStyle = useMemo(() => ({ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }), [drawerWidth]);

  const mobileStyle = useMemo(
    () => ({
      display: { xs: "block", md: "none" },
      "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
    }),
    [drawerWidth]
  );

  return (
    <Box component="nav" sx={navStyle}>
      <Drawer
        container={isDesktop ? undefined : container}
        variant={isDesktop ? "permanent" : "temporary"}
        open={isDesktop ? true : mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={isDesktop ? desktopStyle : mobileStyle}
      >
        {id && <DrawerList path={path} handleDrawerToggle={handleDrawerToggle} />}
      </Drawer>
    </Box>
  );
}

CustomDrawer.defaultProps = {
  mobileOpen: false,
  handleDrawerToggle: () => {},
  window: undefined,
};

CustomDrawer.propTypes = {
  mobileOpen: PropTypes.bool,
  window: PropTypes.func,
  drawerWidth: PropTypes.number.isRequired,
  handleDrawerToggle: PropTypes.func,
  path: PropTypes.string.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
};
