import { Close, EditOutlined } from "@mui/icons-material";
import {
  Typography,
  Box,
  Autocomplete as MUIAutocomplete,
  CircularProgress,
  TextField,
  Skeleton,
  InputAdornment,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: 600,
  },
  optional: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray",
    marginLeft: 6,
  },
  invisibleInput: {
    appearance: "none",
    border: "none",
    outline: "none",
    margin: 0,
    padding: 0,
    width: "100%",
    height: 1,
    marginBottom: -1,
    opacity: 0.1,
    fontSize: 20,
  },
  textField: {
    "&> *": {
      borderRadius: 6,
    },
  },
  list: {
    borderRadius: 6,
    background: "#f3f3f3",
  },
  root: { padding: "9px !important", gap: 6 },
  skeleton: {
    height: 93,
    borderRadius: 4,
    marginTop: -10,
  },
  chip: {
    backgroundColor: "#00A59B",
    color: "white",
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "14px",
    letterSpacing: 0.6,
    paddingRight: 6,
    "& svg": {
      fontSize: 8,
      marginRight: 5,
    },
    "& path": {
      color: "white",
      height: 8,
      width: 8,
      fontSize: 8,
    },
  },
}));

const getIcon = (loading, disabled, onOpen) => {
  if (disabled) {
    return null
  }
  if (loading) {
    return <CircularProgress color="inherit" />
  }
  return <EditOutlined onClick={onOpen} />;
}

export default function Autocomplete({
  loading: loadingData,
  name,
  label,
  options = [],
  handleInputChange = () => {},
  required,
  multiple,
  startIcon,
  onChange,
  disabled,
  InfoButton,
  ...rest
}) {
  const classes = useStyles();
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState(field.value?.name || field.value?.label || field.value?.title || "");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => setOpen(false), []);

  const onOpen = useCallback(async () => {
    if (disabled) {
      return;
    }
    setOpen(true);
    setLoading(true);
    await handleInputChange(inputValue);
    setLoading(false);
  }, [handleInputChange, inputValue, disabled]);

  const onInputChange = useCallback(
    async (event, newValue) => {
      setInputValue(newValue);
      setLoading(true);
      await handleInputChange(newValue);
      setLoading(false);
    },
    [handleInputChange]
  );

  const handleChange = useCallback(
    (event, value) => {
      helpers.setValue(value || {});
      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [helpers, onChange]
  );

  const iconStyle = useMemo(() => ({ marginLeft: 1, color: "text.strong" }), []);

  const helperText = useMemo(() => {
    if (!meta.touched) {
      return " ";
    }
    if (typeof meta.error === "string") {
      return meta.error || " ";
    }
    return Object.values(meta.error || {})[0] || " ";
  }, [meta]);

  return (
    <Box>
      <Typography color={meta.touched && !!meta.error ? "red" : "text.primary"} gutterBottom className={classes.label}>
        {label}
        {required && "*"}
        {!required && !disabled && (
          <Typography display="inline" className={classes.optional}>
            (optional)
          </Typography>
        )}
        {InfoButton}
      </Typography>
      {loadingData ? (
        <Skeleton animation={false} className={classes.skeleton} />
      ) : (
        <MUIAutocomplete
          fullWidth
          filterSelectedOptions
          open={open}
          onOpen={onOpen}
          onClose={handleClose}
          loading={loading}
          options={options}
          getOptionLabel={(option) => option.name || option.label || option.title || ""}
          classes={{ paper: classes.list, inputRoot: classes.root }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          inputValue={inputValue}
          onInputChange={onInputChange}
          value={field.value}
          onChange={handleChange}
          multiple={multiple}
          id={name}
          disabled={disabled}
          {...rest}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} label={option.name} className={classes.chip} deleteIcon={<Close color="error" />} />
            ))
          }
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                error={meta.touched && !!meta.error}
                helperText={helperText}
                classes={{ root: classes.textField }}
                name={name}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: getIcon(loading, disabled, onOpen) ,
                  startAdornment: (
                    <>
                      {startIcon ? (
                        <InputAdornment sx={iconStyle} position="start">
                          {startIcon}
                        </InputAdornment>
                      ) : null}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
              <input name={name} className={classes.invisibleInput} readOnly />
            </>
          )}
        />
      )}
    </Box>
  );
}

Autocomplete.defaultProps = {
  loading: false,
  name: " ",
  label: " ",
  options: [],
  handleInputChange: () => {},
  required: false,
  multiple: false,
  startIcon: undefined,
  onChange: () => {},
  disabled: false,
  InfoButton: null
};

Autocomplete.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  handleInputChange: PropTypes.func,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  startIcon: PropTypes.shape({}),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  InfoButton: PropTypes.element
};
