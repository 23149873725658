export const YEARS = [
  { name: "Year 7", id: 1, value: 7 },
  { name: "Year 8", id: 2, value: 8 },
  { name: "Year 9", id: 3, value: 9 },
  { name: "Year 10", id: 4, value: 10 },
  { name: "Year 11", id: 5, value: 11 },
  { name: "Year 12", id: 6, value: 12 },
  { name: "Year 13", id: 7, value: 13 },
  { name: "Year 13+", id: 8, value: 14 },
];

export const DAYS_ORDER = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const TERMS_AND_CONDITIONS =
  "https://service.sva.org.nz/terms-and-conditions/";
export const INSTITUTION = "School / Organisation";

// simple phone number validation: an optional +, followed by up to 18 numbers, spaces or hyphens
export const PHONE_REG_EXP = /^([+0-9])([0-9 -]{1,18})$/;

export const COUNTRIES = [
  {
    name: "nz",
    code: "+64",
  },
  {
    name: "au",
    code: "+61",
  },
];
