import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useGetYearlyPopup from "../hooks/useGetYearlyPopup";

function UpdateDetailsDialog() {
  const { displayPopUp, handleDismiss, handleDismissUntilNextYear, handleDismissForAWeek, handleViewProfile } = useGetYearlyPopup();

  return (
    <Dialog open={displayPopUp} onClose={handleDismiss}>
      <DialogTitle>Update details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Have you recently changed school, organisation or year level? Please double-check your registered details now and make sure that
          we have your correct information.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismissUntilNextYear} color="error">
          Dismiss
        </Button>
        <Button onClick={handleDismissForAWeek}>Remind me next week</Button>
        <Button onClick={handleViewProfile} variant="contained">
          Confirm Details
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateDetailsDialog;
