import { Typography, Box, TextField as MUITextField, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useField } from "formik";
import { useCallback } from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  label: { fontSize: 14, fontWeight: 600 },
  optional: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray",
    marginLeft: 6,
  },
  textField: {
    "&> *": {
      borderRadius: 6,
    },
  },
  skeleton: {
    height: 93,
    borderRadius: 4,
    marginTop: -10,
  },
}));

export default function TextField({ loading, name, label, type, required, rows, onChange, ...rest }) {
  const classes = useStyles();
  const [field, meta] = useField(name);

  const handleChange = useCallback(
    (e) => {
      field.onChange(e);
      if (typeof onChange === "function") {
        onChange(e);
      }
    },
    [field, onChange]
  );

  return (
    <Box>
      <Typography color={(meta.touched && meta.error) || rest.error ? "error" : "text.primary"} gutterBottom className={classes.label}>
        {label}
        {required && "*"}
        {!required && !rest.disabled && <Typography display="inline" className={classes.optional}>(optional)</Typography>}
      </Typography>
      {loading ? (
        <Skeleton animation={false} className={classes.skeleton} />
      ) : (
        <MUITextField
          fullWidth
          rows={rows || 5}
          classes={{ root: classes.textField }}
          name={field.name}
          value={field.value}
          onChange={handleChange}
          error={meta.touched && !!meta.error}
          helperText={(meta.touched && meta.error) || " "}
          type={type}
          {...rest}
        />
      )}
    </Box>
  );
}

TextField.defaultProps = {
  loading: false,
  name: "",
  label: "",
  type: "",
  required: false,
  rows: 5,
  onChange: undefined,
};

TextField.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
};
