import { useCallback, useMemo } from "react";
import { Box, Backdrop, Card, CardContent, Dialog, Typography, IconButton } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";

import dayjs from "dayjs";
import axios from "../utils/axios";
import IndividualActivityForm from "./IndividualActivityForm";
import { validateEndDate } from "../utils/helpers";

const cardPadding = { padding: [0, 2, 4] };

const useStyles = makeStyles(() => ({
  cardContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function LogAnActivity({ open, onClose, initialValuesToDuplicate }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useSelector((state) => state.profile);

  const initialValues = useMemo(
    () => ({
      goal: null,
      starts: null,
      ends: null,
      title: "",
      description: "",
      skills: [],
      location: null,
      address: "",
      activity_type: null,
      ...initialValuesToDuplicate,
    }),
    [initialValuesToDuplicate]
  );

  const LogActivitySchema = useCallback(
    () =>
      Yup.object().shape({
        goal: Yup.object()
          .required("Please choose a goal")
          .shape({ id: Yup.number().required("Please choose a goal") })
          .nullable(),
        activity_type: Yup.object()
          .shape({ id: Yup.number() })
          .nullable(),
        starts: Yup.date().typeError("Please enter a date")
          .min(dayjs().subtract(5, "year"), "Activity must be in the last 5 years")
          .max(dayjs(), "Activity must be in the past").required("Start date is required"),
        ends: validateEndDate(),
        skills: Yup.array().of(Yup.object().shape({ id: Yup.number() })),
        title: Yup.string().min(1, "Too Short!").max(50, "Too Long!").required("Required"),
        description: Yup.string().min(10, "Too Short!").max(300, "Too long!").required("Required"),
        location: Yup.object()
          .required("Please choose a region")
          .shape({ id: Yup.number().required("Please choose a region") })
          .nullable(),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      const { starts, ends, title, description, skills, location, goal, address, activity_type: activityType } = values;
      const obj = {
        goal_id: goal?.id,
        starts,
        ends,
        title,
        description,
        user_id: id,
        mode: "individual",
        skills: (skills || []).map((s) => ({ id: s.id })),
        location_id: location?.id,
        activity_type_id: activityType?.id,
        address,
      };
      await axios.post("activities", obj);
      enqueueSnackbar("Activity added", { variant: "success" });
      onClose(true);
    },
    [id, onClose, enqueueSnackbar]
  );

  return (
    <Dialog
      BackdropComponent={styled(Backdrop, {
        name: "MuiModal",
        slot: "Backdrop",
        overridesResolver: (props, styles) => styles.backdrop,
      })({ zIndex: -1 })}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      transitionDuration={{ appear: 0 }}
    >
      <Card sx={cardPadding}>
        <CardContent className={classes.cardContent}>
          <Box width="100%" display="flex" flexDirection="row" mb={1}>
            <Box flexGrow={1} />
            <Typography sx={{ fontSize: 28, fontWeight: 800 }}>Log an activity</Typography>
            <Box flexGrow={1} justifyContent="flex-end" display="flex">
              <IconButton onClick={onClose}>
                <Close color="primary" />
              </IconButton>
            </Box>
          </Box>
          <IndividualActivityForm initialValues={initialValues} LogActivitySchema={LogActivitySchema} handleSubmit={handleSubmit} />
        </CardContent>
      </Card>
    </Dialog>
  );
}

LogAnActivity.defaultProps = {
  initialValuesToDuplicate: {},
};

LogAnActivity.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValuesToDuplicate: PropTypes.shape({}),
};
